import axios from "axios";
import { DateTime } from "luxon";
import store from "@/store/store";

export default {
    saveBlob(response) {
        function getFilename(response) {
            let disposition = response.headers["content-disposition"];

            if (disposition && disposition.indexOf("attachment") !== -1) {
                let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                let matches = filenameRegex.exec(disposition);

                if (matches != null && matches[1]) {
                    return matches[1].replace(/['"]/g, "");
                }

                return "export.xslx";
            }
        }

        let blob = new Blob([response.data], {
            type: response.headers["content-type"],
        });
        let downloadUrl = window.URL.createObjectURL(blob);
        let filename = getFilename(response);
        let link = document.createElement("a");

        if (typeof link.download === "undefined") {
            window.location.href = downloadUrl;
        } else {
            link.href = downloadUrl;
            link.download = filename;
            document.body.appendChild(link);
            link.click();
        }
    },

    downloadAsExcel(data) {
        if (data == null || data.items == null || !data.items.length) {
            store.dispatch("alert/error", "There is no data to export.");
            return;
        }

        function isObject(value) {
            return typeof value === "object" && value !== null;
        }

        function hasProperties(value) {
            for (let key in value) {
                if (Object.prototype.hasOwnProperty.call(value, key)) {
                    return true;
                }
            }
            return false;
        }

        function isIdLabel(value) {
            return "id" in value && "label" in value;
        }

        let items = [];
        data.items.forEach(e => {
            let item = Object.assign({}, e);

            for (let property in item) {
                let value = item[property];

                if (!isObject(value)) {
                    continue;
                }

                if (isIdLabel(value)) {
                    item[property] = value.label;
                } else if (!hasProperties(value)) {
                    item[property] = "";
                }
            }

            items.push(item);
        });

        let parameters = {
            title: data.title,
            table: items,
            clientNow: DateTime.local().toISO(),
        };

        return axios
            .post("/api/Export/ToExcel", parameters, { responseType: "blob" })
            .then(this.saveBlob)
            .catch(error => {
                store.dispatch("alert/error", "There was an error downloading the file. " + error);
            });
    },
};
