import Vue from "vue";
import dateUtility from "@/services/dateUtility";

export default Vue.mixin({
    methods: {
        formatIsoAsTime(value) {
            return dateUtility.formatIsoAsTimeWithOffset(value);
        },

        formatIsoAsTimespan(value) {
            return dateUtility.formatIsoAsTimespan(value);
        },

        formatIsoAsDate(value) {
            return dateUtility.formatIsoAsDate(value);
        },

        formatAddress(address) {
            if (this.isEmpty(address)) {
                return "";
            }
            return address.street + ", "
                + address.city + ", "
                + address.state;
        },

        formatCoordinates(coordinates) {
            if (this.isEmpty(coordinates)) {
                return "";
            }
            let latitude = coordinates.latitude.toString().substring(0, 10);
            let longitude = coordinates.longitude.toString().substring(0, 10);
            return "(" + latitude + ", " + longitude + ")";
        },

        formatEnum(value) {
            return value.replace(/([A-Z])/g, " $1").trim();
        },

        formatLongString(value, length) {
            if (this.isEmpty(value)) {
                return "";
            }
            return value.substring(0, length) + (value.length > length ? "..." : "");
        },
    },
});
