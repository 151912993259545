<template>
    <v-app>
        <the-alert class="alert-bar" />
        <template v-if="isLoaded">
            <v-navigation-drawer
                v-if="isLoggedIn"
                class="side-nav-container"
                app
                :value="navigationIsOpen"
                :clipped="$vuetify.breakpoint.lgAndUp">
                <div class="fill-height d-flex flex-column justify-space-between">
                    <the-menu />
                    <div class="nav-version">
                        Version: {{ versionNumber }}
                    </div>
                </div>
            </v-navigation-drawer>
            <v-app-bar
                v-if="isLoggedIn"
                flat
                dense
                class="app-bar-container"
                :clipped-left="$vuetify.breakpoint.lgAndUp"
                dark
                color="primary"
                app>
                <v-app-bar-nav-icon @click="navToggle" />
                <v-spacer />
                <v-toolbar-title class="brand-text">
                    B-QUAL Test Library
                </v-toolbar-title>
                <v-spacer />
                <the-app-bar-content />
            </v-app-bar>
            <v-main>
                <router-view class="router-container" />
            </v-main>
        </template>
        <template v-else>
            <v-progress-circular
                :size="70"
                :width="7"
                indeterminate
                color="tertiary" />
        </template>
    </v-app>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import axios from 'axios';

export default {
    components: {
        theAlert: () => import("@/components/TheAlert"),
        theAppBarContent: () => import("@/components/TheAppBarContent"),
        theMenu: () => import("@/components/TheMenu"),
    },

    data() {
        return {
            navigationIsOpen: true,
            versionNumber: null
        };
    },

    computed: {
        ...mapState({
            isLoggedIn: s => s.authentication.isLoggedIn,
            user: s => s.authentication.user,
        }),

        ...mapGetters({
            isLoaded: "load/isLoaded"
        })
    },

    async mounted() {
        await this.$store.dispatch("load/onStartup");
        this.getVersion();
    },

    methods: {
        navToggle() {
            this.navigationIsOpen = !this.navigationIsOpen;
        },
        async getVersion() {
            let response = await axios.get('/api/Version');
            this.versionNumber = response.data.versionNumber;
        },
    },
};
</script>

<style lang="scss">
    @import "assets/style/theme.scss";

    .router-container {
        width: 100%;
        height: 100%;
        background-color: $color-background;
        padding: 20px;
    }

    .alert-bar {
        z-index: 1000;
        position: absolute;
        left: 0;
        right: 0;
        top: 60px;
        margin: auto;
        width: fit-content;
    }

    .nav-version {
        padding: 20px 40px;
        text-align: center;
    }
</style>
