import axios from "axios";
import schemaService from "@/services/schemaService"

export const schema = {
    namespaced: true,
    state: {
        schema: null,
        tableSchema: null,
        enums: null,
        isLoaded: false
    },
    actions: {
        async load({ commit }) {
            let response = await axios.get("/api/Schema");
            let schema = response.data;
            let enums = (await axios.get("/api/Schema/GetEnums")).data;
            await schemaService.addFieldsToSchema(schema, Object.keys(enums));
            commit("initialize", { schema, enums });
        },
        clear({ commit }) {
            commit("clear");
        }
    },
    mutations: {
        initialize(state, schemaState) {
            state.schema = schemaState.schema;
            state.enums = schemaState.enums;
            state.tableSchema = schemaService.buildTableSchema(schemaState.schema);
            state.isLoaded = true;
        },
        clear(state) {
            state.schema = null;
            state.tableSchema = null;
            state.enums = null;
            state.isLoaded = false;
        }
    },
};
