import Vue from "vue";
import schemaService from "@/services/schemaService.js"
import errorUtility from "@/services/errorUtility.js";
import objectUtility from "@/services/objectUtility.js";

export default Vue.mixin({
    methods: {
        isEmpty(value) {
            return objectUtility.isEmpty(value);
        },
        handleError(error) {
            errorUtility.handleError(error);
        },
        async getLabel(item, fields) {
            return schemaService.getLabel(item, fields);
        },
        getSchema(name) {
            return schemaService.getSchema(name);
        },
        getTableSchema(name) {
            return schemaService.getTableSchema(name);
        },
    },
});
