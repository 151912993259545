import Router from "vue-router";
import qs from "qs";
import router from "@/routes";

const publicPaths = [
    "/login",
    "/reset-password",
    "/request-reset-password",
    "/new-user-set-password"
];

export function currentRouteIsPublic() {
    return routeIsPublic(router.currentRoute);
}

export function routeIsPublic(route) {
    return publicPaths.some(publicPath =>
        publicPath == route.path
    );
}

export function goTo(path) {
    if (router.currentRoute.path !== path) {
        router.push(path);
    }
}

function getRouter(routes) {
    return new Router({
        mode: "history",
        parseQuery(query) {
            return qs.parse(query, {
                decoder(str, decoder, charset) {
                    const strWithoutPlus = str.replace(/\+/g, " ");
                    if (charset === "iso-8859-1") {
                        // unescape never throws, no try...catch needed:
                        return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, unescape);
                    }

                    if (/^(\d+|\d*\.\d+)$/.test(str)) {
                        return parseFloat(str);
                    }

                    const keywords = {
                        true: true,
                        false: false,
                        null: null,
                        undefined,
                    };
                    if (str in keywords) {
                        return keywords[str];
                    }

                    // utf-8
                    try {
                        return decodeURIComponent(strWithoutPlus);
                    } catch (e) {
                        return strWithoutPlus;
                    }
                },
            });
        },
        stringifyQuery(query) {
            const result = qs.stringify(query, { encode: false });
            return result ? "?" + result : "";
        },
        routes: routes,
    });
}

export default {
    getRouter,
    goTo,
    routeIsPublic,
    currentRouteIsPublic
};
