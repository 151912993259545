import Vue from "vue";
import vuetify from "./plugins/vuetify";
import App from "./App.vue";
import mixins from "./mixins/mixins";
import exporter from "./export/exporter";
import router from "./routes";
import store from "./store/store";
import setupInterceptor from './helpers/interceptor'

import "@mdi/font/css/materialdesignicons.css";

mixins; // We need to do this because of unused variable warnings

Vue.config.productionTip = false;

setupInterceptor();

new Vue({
    vuetify,
    router,
    store,
    exporter,
    render: h => h(App),
}).$mount("#app");
