export default [
    {
        path: "/login",
        name: "login",
        component: () => import("@/views/authentication/Login.vue"),
    },
    {
        path: "/change-password",
        name: "changePassword",
        component: () => import("@/views/authentication/ChangePassword.vue"),
    },
    {
        path: "/reset-password",
        name: "resetPassword",
        component: () => import("@/views/authentication/ResetPassword.vue"),
    },
    {
        path: "/request-reset-password",
        name: "requestResetPassword",
        component: () => import("@/views/authentication/RequestResetPassword.vue"),
    },
    {
        path: "/new-user-set-password",
        name: "newUserSetPassword",
        component: () => import("@/views/authentication/NewUserSetPassword.vue"),
    },
];
