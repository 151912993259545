import axios from "axios";
import store from "@/store/store";
import { equal } from "@/services/filtering"
import { isEmpty } from "@/services/objectUtility"

export default {
    login,
    logout,
    getCurrentUser,
    hasClaim,
    getLabTesterId,
    getLabTesterIdFromUserId
};

async function login(emailAddress, password) {
    const response = await axios.post("/api/Security/Login", { emailAddress, password });
    return response.data;
}

async function logout() {
    await axios.post("/api/Security/Logout");
}

async function getCurrentUser() {
    const response = await axios.get("/api/Security/CurrentUser");
    const user = response.data;
    return !user ? null : user;
}

function getLabTesterId() {
    return store.state.authentication.labTesterId;
}

async function getLabTesterIdFromUserId(userId) {
    let params = {
        filter: equal("userId", userId, "guid")
    };
    let response = await axios.post("/api/LabTesters/List", params);
    let labTester = response.data.items[0];
    return isEmpty(labTester) ? null : labTester.id;
}

function hasClaim(name, requiresWrite) {
    const user = store.state.authentication.user;

    if (!user) {
        return false;
    }
    return user.claims.some(claim =>
        claim.name.toLowerCase() == name.toLowerCase() &&
        (!requiresWrite || claim.value == "ReadWrite")
    );
}
