import axios from "axios";
import dateUtility from "@/services/dateUtility";
import store from '@/store/store.js';
import { isEmpty } from "@/services/objectUtility"

const schemaDictionary = {
    AddressModel: () => import("@/components/FormAddressField"),
    Int32: () => import("@/components/FormNumber"),
    String: () => import("@/components/FormTextField"),
    Guid: () => import("@/components/FormAutocomplete"),
    Char: () => import("@/components/FormTextField"),
    Decimal: () => import("@/components/FormNumber"),
    Double: () => import("@/components/FormNumber"),
    Single: () => import("@/components/FormNumber"),
    Boolean: () => import("@/components/FormCheckbox"),
    DateTimeOffset: () => import("@/components/FormDateTimeField"),
    GeographyPoint: () => import("@/components/FormCoordinateField"),
    'Guid[]': () => import("@/components/FormFilesField"),
    'List`1': () => import("@/components/FormSearchSelectMultipleTable")
};
const formEnum = () => import("@/components/FormEnum");
const labelPropertiesToIgnore = [
    "id",
    "createdAt",
    "updatedAt",
    "addressId",
    "harvestingId",
    "auditorId",
    "codeOfPracticeDeclarationFileId",
    "sampleSetIds"
];
const tableFieldsToIgnore = [
    "Id",
    "File Ids",
    "Auditor",
    "Is Inactive",
    "Sample Set Ids"
];
const formFieldsToIgnore = [
    "addressId",
    "id",
    "isInactive"
];

function buildTableSchema(schema) {
    let tableSchema = [];
    for (let i = 0; i < schema.length; i++) {
        tableSchema.push(getTableSchemaForPageSchema(schema[i]));
    }
    return tableSchema;
}

function getTableSchemaForPageSchema(pageSchema) {
    let headers = [];
    for (let i = 0; i < pageSchema.fields.length; i++) {
        let field = pageSchema.fields[i];
        if (tableFieldsToIgnore.includes(field.titleSingular)) {
            continue;
        }
        let header = {
            text: field.titleSingular,
            value: field.camelSingular,
            table: field.foreignKeyCamelSingular,
            apiEndpoint: field.foreignKeyPascalPlural,
            formName: field.foreignKeyPascalSingular,
            type: field.type,
            nullableType: field.nullableType
        };
        headers.push(header);
    }
    headers.push({ text: "", value: "action" });
    return {
        headers,
        itemsField: pageSchema.camelPlural,
        editPageName: pageSchema.camelSingular,
        apiMethod: pageSchema.pascalPlural,
        claimName: pageSchema.pascalPlural,
        formTitle: pageSchema.titleSingular,
        excelTitle: pageSchema.titlePlural,
    };
}

async function addFieldsToSchema(schema, enums) {
    for (let i = 0; i < schema.length; i++) {
        schema[i].fields = getFieldsFromSchema(schema[i], enums);
    }
}

function getFieldsFromSchema(schema, enums) {
    let fields = [];
    for (let i = 0; i < schema.fields.length; i++) {
        let schemaField = schema.fields[i];
        let field = {
            titleSingular: schemaField.titleSingular,
            camelSingular: schemaField.camelSingular,
            pascalSingular: schemaField.pascalSingular,
            foreignKeyCamelSingular:
                schemaField.foreignKeyCamelSingular,
            foreignKeyPascalPlural: schemaField.foreignKeyPascalPlural,
            foreignKeyPascalSingular:
                schemaField.foreignKeyPascalSingular,
            rules: [],
            type: schemaField.type,
            nullableType: schemaField.nullableType,
            attributes: schemaField.attributes
        };
        if (formFieldsToIgnore.includes(schemaField.camelSingular)) {
            continue;
        }

        for (let entry of enums) {
            schemaDictionary[entry] = formEnum;
        }
        let dictionaryValue = schemaDictionary[schemaField.type];
        if (schemaField.foreignKeyPascalSingular === "File") {
            dictionaryValue = () => import("@/components/FormFileUpload")
        }
        field.component = dictionaryValue;
        field.type = schemaField.type;
        for (let j = 0; j < schemaField.attributes.length; j++) {
            let attribute = schemaField.attributes[j];
            switch (attribute.$type) {
            case "MaxLength":
                field.rules.push(
                    v =>
                        !(v && v.length > attribute.length) ||
                            field.text +
                            " must be less than " +
                            attribute.length +
                            " characters"
                );
                field.maxLength = attribute.length;
                break;
            case "Required":
                field.rules.push(
                    v => (v !== null && v !== '') || field.titleSingular + " is required"
                );
                field.isRequired = true;
            }
        }
        fields.push(field);
    }
    return fields;
}

async function getIdLabels(items, fields) {
    let idLabels = items.map(e => {
        return {
            id: e.id,
            label: "",
            item: e
        };
    });
    let hasFoundDate = false;
    let enums = Object.keys(store._modules.root._children.schema.state.enums)
        .map(e => e.charAt(0).toLowerCase() + e.slice(1));
    for (let i = 0; i < fields.length; i++) {
        let field = fields[i];
        let property = field.camelSingular;
        if (property === "id" ||
            labelPropertiesToIgnore.includes(property) ||
            enums.includes(property) ||
            (field.type !== "String" &&
                field.type !== "DateTimeOffset" &&
                field.type !== "Guid") ||
            (property.endsWith("Id") && fields === null)) {
            continue;
        }
        if (property.endsWith("Id")) {
            let itemIds = items
                .filter(e => !isEmpty(e[property]))
                .map(e => e[property]);
            if (itemIds.length === 0) {
                continue;
            }
            let foreignKeyItems = (await axios.post(
                "/api/" + field.foreignKeyPascalPlural + "/ListFromIds",
                itemIds
            )).data.items;
            for (let j = 0; j < foreignKeyItems.length; j++) {
                let foreignKeyItem = foreignKeyItems[j];
                let foreignKeyIdLabels = idLabels
                    .filter(e => e.item[property] === foreignKeyItem.id);
                for (let k = 0; k < foreignKeyIdLabels.length; k++) {
                    let foreignKeyIdLabel = foreignKeyIdLabels[k];
                    foreignKeyIdLabel.label += await getLabel(foreignKeyItem, null);
                }
            }
        }
        else if (field.type === "DateTimeOffset") {
            if (!hasFoundDate && fields !== null) {
                idLabels.forEach(e =>
                    e.label += dateUtility.formatIsoAsDate(e.item[property]) + " ");
                hasFoundDate = true;
            }
        } else {
            idLabels.forEach(e => e.label += e.item[property] + " ");
        }
        if (property === "name") {
            return idLabels;
        }
    }
    return idLabels;
}

async function getLabel(item, fields) {
    let label = "";
    let hasFoundDate = false;
    let enums = Object.keys(store._modules.root._children.schema.state.enums)
        .map(e => e.charAt(0).toLowerCase() + e.slice(1));
    for (let property in item) {
        let value = item[property];
        if (property === "id" ||
            isEmpty(value) ||
            typeof value !== "string" ||
            labelPropertiesToIgnore.includes(property) ||
            enums.includes(property) ||
            (property.endsWith("Id") && fields === null)) {
            continue;
        }
        if (property.endsWith("Id")) {
            let field = fields.filter(e => e.camelSingular === property)[0];
            let response = await axios.get("/api/" + field.foreignKeyPascalPlural
                + "/" + item[property]);
            label += await getLabel(response.data, null) + " ";
        }
        else if (dateUtility.isDate(value)) {
            if (!hasFoundDate && fields !== null) {
                label += dateUtility.formatIsoAsDate(value) + " ";
                hasFoundDate = true;
            }
        } else {
            label += value + " ";
        }
        if (property === "name") {
            return label;
        }
    }
    return label;
}

function getSchema(name) {
    let schema = store.state.schema.schema;
    let filteredSchema = filterSchema(schema, name);
    if (isEmpty(filteredSchema)) {
        throw "Schema with name " + name + " does not exist";
    }
    return filteredSchema;
}

function filterSchema(schema, name) {
    return schema.filter(
        e =>
            name === e.pascalPlural ||
            name === e.pascalSingular ||
            name === e.camelSingular ||
            name === e.camelPlural ||
            name === e.titleSingular ||
            name === e.titlePlural
    )[0];
}

function getTableSchema(name) {
    let tableSchema = store.state.schema.tableSchema;
    return filterTableSchema(tableSchema, name);
}

function filterTableSchema(schema, name) {
    return schema.filter(
        e =>
            name === e.apiMethod ||
            name === e.claimName ||
            name === e.editPageName ||
            name === e.excelTitle ||
            name === e.formTitle ||
            name === e.itemsField
    )[0];
}

export default {
    addFieldsToSchema,
    buildTableSchema,
    getIdLabels,
    getLabel,
    labelPropertiesToIgnore,
    getSchema,
    getTableSchema
};
