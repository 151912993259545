import Vue from "vue";
import Router from "vue-router";
import authenticationRoutes from "./authenticationRoutes";
import dataTableRoutes from "./dataTableRoutes";
import editViewRoutes from "./editViewRoutes";
import routeService from "@/services/routeService";

Vue.use(Router);

let routes = [
    {
        path: "/",
        name: "Home",
        component: () => import("@/views/Home.vue"),
    },
    {
        path: "/master-router",
        name: "MasterRouter",
        component: () => import("@/views/MasterRouter.vue"),
    },
    {
        path: "/lab-test-dashboard",
        name: "labTestDashboard",
        component: () => import("@/views/labTestDashboard/LabTestDashboard.vue"),
    },
    {
        path: '/lab-test-management',
        name: 'labTestManagement',
        component: () => import('@/views/lab/Labs.vue')
    },
    {
        path: '/test-management',
        name: 'testManagement',
        component: () => import('@/views/testGroup/TestGroups.vue')
    },
    {
        path: '/sample-set-management',
        name: 'sampleSetManagement',
        component: () => import('@/views/sampleSet/SampleSets.vue')
    },
    {
        path: "/sample-set-test-report",
        name: "SampleSetTestReport",
        component: () => import("@/reports/SampleSetTestReport.vue"),
    },
    {
        path: "/sample-report",
        name: "SampleReport",
        component: () => import("@/reports/SampleReport.vue"),
    },
    {
        path: "/lab-client-organisation-sample-sets-report",
        name: "LabClientOrganisationSampleSetsReport",
        component: () => import("@/reports/LabClientOrganisationSampleSetsReport.vue"),
    },
    {
        path: "/lab-testers",
        name: "labTesters",
        component: () =>
            import(
                /* webpackChunkName: "appTableView" */ "@/components/AppTableView.vue"
            ),
        staticProps: {
            table: () => import("@/views/LabTesters.vue"),
            pageTitle: "Lab Testers"
        },
    },
    {
        path: "/data-scientists",
        name: "dataScientists",
        component: () =>
            import(
                /* webpackChunkName: "appTableView" */ "@/components/AppTableView.vue"
            ),
        staticProps: {
            table: () => import("@/views/DataScientists.vue"),
            pageTitle: "Data Scientists"
        },
    },
    {
        path: "/lab-client-organisations",
        name: "labClientOrganisations",
        component: () =>
            import(
                /* webpackChunkName: "appTableView" */ "@/components/AppTableView.vue"
            ),
        staticProps: {
            table: () => import("@/views/LabClientOrganisations.vue"),
            pageTitle: "Lab Client Organisations"
        },
    },
    {
        path: "/test-results",
        name: "testResults",
        component: () => import("@/components/AppTableView.vue"),
        staticProps: { pageTitle: "Test Results", form: () => import("@/forms/TestResultForm") },
    },
    {
        path: "/samples",
        name: "samples",
        component: () => import("@/components/AppTableView.vue"),
        staticProps: { pageTitle: "Samples", table: () => import("@/tables/SamplesTable") },
    },
    {
        path: "/sample-import",
        name: "sampleImport",
        component: () => import("@/views/ImportSamples.vue")
    },
    {
        path: "*",
        redirect: "/",
    },
];

routes = routes.concat(authenticationRoutes).concat(dataTableRoutes).concat(editViewRoutes);

// Pass the query string as props into all routes.
routes.forEach(r => (r.props = route => Object.assign({}, route.query, r.staticProps)));

const router = routeService.getRouter(routes);

export default router;
