import Vue from "vue";
import securityService from "@/services/securityService";

export default Vue.mixin({
    methods: {
        hasClaim(name, requiresWrite) {
            return securityService.hasClaim(name, requiresWrite);
        },
        hasRole(name) {
            return securityService.hasRole(name);
        },
        canRead(claim) {
            return securityService.hasClaim(claim);
        },
        canEdit(claim) {
            return securityService.hasClaim(claim, true);
        },
        canDelete(claim) {
            return securityService.hasClaim(claim, true);
        },
    },
});
