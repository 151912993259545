let appEditView = () =>
    import(
        /* webpackChunkName: "appEditView" */ "@/components/AppEditView.vue"
    );

export default [
    {
        path: "/address/:id",
        name: "address",
    },
    {
        path: "/claim/:id",
        name: "claim",
    },
    {
        path: "/contact/:id",
        name: "contact",
    },
    {
        path: "/country/:id",
        name: "country",
    },
    {
        path: "/culture/:id",
        name: "culture",
    },
    {
        path: "/facility/:id",
        name: "facility",
    },
    {
        path: "/file/:id",
        name: "file",
    },
    {
        path: "/floral-variety/:id",
        name: "floralVariety",
    },
    {
        path: "/format/:id",
        name: "format",
    },
    {
        path: "/harvesting/:id",
        name: "harvesting",
    },
    {
        path: "/lab/:id",
        name: "lab",
    },
    {
        path: "/lab-client-organisation/:id",
        name: "labClientOrganisation",
    },
    {
        path: "/lab-test/:id",
        name: "labTest",
    },
    {
        path: "/lab-tester/:id",
        name: "labTester",
    },
    {
        path: "/load/:id",
        name: "load",
    },
    {
        path: "/member/:id",
        name: "member",
    },
    {
        path: "/region-type/:id",
        name: "regionType",
    },
    {
        path: "/role/:id",
        name: "role",
    },
    {
        path: "/sample/:id",
        name: "sample",
    },
    {
        path: "/sample-set/:id",
        name: "sampleSet",
    },
    {
        path: "/sample-test/:id",
        name: "sampleTest",
    },
    {
        path: "/test/:id",
        name: "test",
    },
    {
        path: "/test-group/:id",
        name: "testGroup",
    },
    {
        path: "/time-zone/:id",
        name: "timeZone",
    },
    {
        path: "/unit/:id",
        name: "unit",
    },
    {
        path: "/user/:id",
        name: "user",
    },
].map(e => {
    return { ...e, component: appEditView };
});
