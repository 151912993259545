import security from "@/services/securityService.js";
import { goTo, currentRouteIsPublic } from "@/services/routeService";
import { handleError } from "@/services/errorUtility";
import { isEmpty } from "@/services/objectUtility"

function getDefaultState() {
    return {
        user: null,
        isLoggedIn: false,
        isLoaded: false,
        loggingIn: false,
        labTesterId: null
    }
}

const state = getDefaultState;

const actions = {
    async load({ commit }) {
        let user = await security.getCurrentUser();

        if (!isEmpty(user) && user != "") {
            let labTesterId = await security.getLabTesterIdFromUserId(user.id);
            commit("setLabTesterId", labTesterId);
            commit("user", user);
        }
        setTimeout(async function () {
            if ((isEmpty(user) || user == "") && !currentRouteIsPublic()) {
                goTo("/login");
            }
            commit("loaded");
        }, 100);
    },

    async login({ commit }, { emailAddress, password }) {
        try {
            commit("loggingIn", true);
            let user = await security.login(emailAddress, password);
            commit("user", user);
            goTo("/")
        }
        catch (error) {
            commit("clear");
            handleError(error);
        }
        finally {
            commit("loggingIn", false);
        }
    },

    async setPasswordSuccess({ commit }, { user }) {
        commit("user", user);
        goTo("/");
    },

    async logout({ commit }) {
        await security.logout();
        commit("clear");
        goTo("/login");
        commit("loaded");
    },

    setUser({ commit }, user) {
        commit("user", user);
    },

    setSettings({ commit, dispatch }, settings) {
        commit("settings", settings);
        dispatch("localisation/load", null, { root: true });
    },
};

const mutations = {
    user(state, user) {
        state.user = user;
        state.isLoggedIn = true;
    },
    settings(state, settings) {
        if (state.user) {
            Object.assign(state.user, settings);
        }
    },
    loaded(state) {
        state.isLoaded = true;
    },
    loggingIn(state, loggingIn) {
        state.loggingIn = loggingIn;
    },
    clear(state) {
        Object.assign(state, getDefaultState());
    },
    setLabTesterId(state, labTesterId) {
        state.labTesterId = labTesterId;
    }
};

export const authentication = {
    namespaced: true,
    state,
    actions,
    mutations
}
