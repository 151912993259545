let appTableView = () =>
    import(
        /* webpackChunkName: "appTableView" */ "@/components/AppTableView.vue"
    );

export default [
    {
        path: "/addresses",
        name: "addresses",
        staticProps: { pageTitle: "Addresses" },
    },
    {
        path: "/claims",
        name: "claims",
        staticProps: { pageTitle: "Claims" },
    },
    {
        path: "/contacts",
        name: "contacts",
        staticProps: { pageTitle: "Contacts" },
    },
    {
        path: "/countries",
        name: "countries",
        staticProps: { pageTitle: "Countries" },
    },
    {
        path: "/cultures",
        name: "cultures",
        staticProps: { pageTitle: "Cultures" },
    },
    {
        path: "/facilities",
        name: "facilities",
        staticProps: { pageTitle: "Facilities" },
    },
    {
        path: "/files",
        name: "files",
        staticProps: { pageTitle: "Files" },
    },
    {
        path: "/floral-varieties",
        name: "floralVarieties",
        staticProps: { pageTitle: "Floral Varieties" },
    },
    {
        path: "/floral-variety-nicknames",
        name: "floralVarietyNicknames",
        staticProps: { pageTitle: "Floral Variety Nicknames" },
    },
    {
        path: "/formats",
        name: "formats",
        staticProps: { pageTitle: "Formats" },
    },
    {
        path: "/harvestings",
        name: "harvestings",
        staticProps: { pageTitle: "Harvestings" },
    },
    {
        path: "/labs",
        name: "labs",
        staticProps: { pageTitle: "Labs" },
    },
    {
        path: "/lab-staff-members",
        name: "labStaffMembers",
        staticProps: { pageTitle: "Lab Staff Members" },
    },
    {
        path: "/lab-tests",
        name: "labTests",
        staticProps: { pageTitle: "Lab Tests" },
    },
    {
        path: "/loads",
        name: "loads",
        staticProps: { pageTitle: "Loads" },
    },
    {
        path: "/members",
        name: "members",
        staticProps: { pageTitle: "Members" },
    },
    {
        path: "/regions",
        name: "regions",
        staticProps: { pageTitle: "Regions" },
    },
    {
        path: "/region-types",
        name: "regionTypes",
        staticProps: { pageTitle: "Region Types" },
    },
    {
        path: "/roles",
        name: "roles",
        staticProps: { pageTitle: "Roles" },
    },
    {
        path: "/role-claims",
        name: "roleClaims",
        staticProps: { pageTitle: "Role Claims" },
    },
    {
        path: "/sample-sets",
        name: "sampleSets",
        staticProps: { pageTitle: "Sample Sets" },
    },
    {
        path: "/sample-set-samples",
        name: "sampleSetSamples",
        staticProps: { pageTitle: "Sample Set Samples" },
    },
    {
        path: "/sample-set-tests",
        name: "sampleSetTests",
        staticProps: { pageTitle: "Sample Set Tests" },
    },
    {
        path: "/sample-tests",
        name: "sampleTests",
        staticProps: { pageTitle: "Sample Tests" },
    },
    {
        path: "/tests",
        name: "tests",
        staticProps: { pageTitle: "Tests" },
    },
    {
        path: "/test-groups",
        name: "testGroups",
        staticProps: { pageTitle: "Test Groups" },
    },
    {
        path: "/test-results",
        name: "testResults",
        staticProps: { pageTitle: "Test Results" },
    },
    {
        path: "/time-zones",
        name: "timeZones",
        staticProps: { pageTitle: "Time Zones" },
    },
    {
        path: "/units",
        name: "units",
        staticProps: { pageTitle: "Units" },
    },
    {
        path: "/users",
        name: "users",
        staticProps: { pageTitle: "Users" },
    },
    {
        path: "/user-roles",
        name: "userRoles",
        staticProps: { pageTitle: "User Roles" },
    },
].map(e => {
    return { ...e, component: appTableView };
});
