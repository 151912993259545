import axios from "axios";

export const google = {
    namespaced: true,
    state: {
        google: null,
        isLoaded: false
    },
    actions: {
        async load({ commit }) {
            let apiKey = await axios.get('/api/GoogleApiKey/');

            let url = 'https://maps.googleapis.com/maps/api/js?key='
                + apiKey.data
                + '&libraries=places&v=weekly';

            let googleScript = document.createElement('script');
            googleScript.setAttribute('src', url);
            document.head.appendChild(googleScript);

            if (window.google) {
                commit("initialize", window.google);
                return
            }
            googleScript.addEventListener('load', () => {
                commit("initialize", window.google);
            });
        },
        clear({ commit }) {
            commit("clear");
        }
    },
    mutations: {
        initialize(state, google) {
            state.google = google;
            state.isLoaded = true;
        },
        clear(state) {
            state.google = null;
            state.isLoaded = false;
        }
    },
};
