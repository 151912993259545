import { DateTime } from "luxon";
import { getTimeZone, getLocale } from "@/services/localisation";

export function formatIsoAsDate(value) {
    if (value === null || typeof value === "undefined") {
        return "";
    }
    let dateTime = DateTime.fromISO(value, { setZone: false });

    let utcDateTime = dateTime.toUTC().setLocale(getLocale());
    let utcPlusOffset = utcDateTime.plus({ minutes: dateTime.offset });
    let datePart = utcPlusOffset.toLocaleString(DateTime.DATE_SHORT);
    return datePart;
}

export function formatIsoAsDateTimeOffset(iso) {
    if (iso === null) return "";
    let dateTime = DateTime.fromISO(iso, { setZone: true });
    let utcDateTime = dateTime.toUTC().setLocale(getLocale());
    let utcPlusOffset = utcDateTime.plus({ minutes: dateTime.offset });
    let datePart = utcPlusOffset.toFormat(DateTime.DATE_SHORT);
    let timePart = utcPlusOffset.toLocaleString(DateTime.TIME_SIMPLE);
    let offset = formatOffset(dateTime.offset);

    // TODO: Only format offset if it's not the same as the current offset.
    return `${datePart} ${timePart.split(" ").join("")} ${offset}`.trimEnd();
}

export function formatIsoAsTime(iso) {
    if (iso === null || typeof iso === "undefined") {
        return "";
    }

    return DateTime
        .fromISO(iso)
        .setLocale(getLocale())
        .toLocaleString(DateTime.TIME_SIMPLE);
}

export function formatIsoAsTimeWithOffset(iso) {
    if (iso === null || typeof iso === "undefined") {
        return "";
    }
    return DateTime
        .fromISO(iso, { setZone: true })
        .toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET);
}

export function formatIsoAsTimespan(iso) {
    if (iso === null || typeof iso === "undefined") {
        return "";
    }

    return DateTime
        .fromISO(iso)
        .set({ milliseconds: 0 })
        .toISOTime({ suppressMilliseconds: true, includeOffset: false });
}

export function setTime(value, time) {
    let dateTime = DateTime.fromISO(value, { setZone: true });
    let date = dateTime.toISODate();
    let offset = toIsoOffset(dateTime.offset);
    return date + "T" + time + offset;
}

export function addDays(value, days) {
    let dateTime = DateTime.fromISO(value, { setZone: true }).plus({
        days: days,
    });
    return dateTime.toISO();
}

export function formatOffset(offset) {
    if (offset === 0) return "UTC";

    let hours = parseInt(Math.abs(offset / 60));
    let minutes = Math.abs(offset % 60);
    let prefix = offset > 0 ? "+" : "-";

    if (minutes === 0) {
        minutes = "";
    }
    else if (minutes < 10) {
        minutes = ":0" + minutes;
    }
    else {
        minutes = ":" + minutes;
    }

    return "UTC" + prefix + hours + minutes;
}

export function toIsoOffset(offset) {
    if (offset === 0) return "Z";

    let hours = parseInt(Math.abs(offset / 60));
    let minutes = Math.abs(offset % 60);
    let prefix = offset > 0 ? "+" : "-";
    if (hours < 10) hours = "0" + hours;
    if (minutes < 10) minutes = "0" + minutes;

    return prefix + hours + ":" + minutes;
}

export function tryParseOffset(offset) {
    // We expect the time offset to be something like this:
    // +8, -8, 8, 8:00, 08:00, +8:00, +08:00, -08:00, Z,
    // 8: 00: 00, 08: 00: 00, +8: 00: 00, +08: 00: 00, -08: 00: 00
    if (offset == null) {
        return { isValid: false };
    }

    if (offset == "Z") {
        // Z = Zulu = UTC+0:00
        return { isValid: true, offset: 0 };
    }

    // value in minutes.
    let value = null;
    if (isInt(offset)) {
        value = parseInt(Number(offset)) * 60;
    }
    else {
        let matches = offset.match(/^([+-]?)(\d?\d):(\d\d)(:\d\d)?$/);
        if (matches != null) {
            let minutes = Number(matches[3]);
            if (minutes >= 60) {
                return { isValid: false };
            }
            let sign = matches[1] === "-" ? -1 : 1;
            value = sign * (Number(matches[2]) * 60 + minutes);
        }
        else {
            return { isValid: false };
        }
    }

    if (value < -12 * 60 || value > 14 * 60) {
        return { isValid: false };
    }
    return { isValid: true, offset: value };
}

export function isInt(value) {
    if (isNaN(value)) {
        return false;
    }
    let x = parseFloat(value);
    return (x | 0) === x;
}

export function getCurrentDateIso() {
    let now = DateTime.local();
    return toDateIso(now);
}

export function toDateIso(date) {
    return date.toISODate() + "T00:00:00" + toIsoOffset(date.offset);
}

export function getDifference(value1, value2) {
    let dateTime1 = DateTime.fromISO(value1, { setZone: true });
    let dateTime2 = DateTime.fromISO(value2, { setZone: true });
    return dateTime1.diff(dateTime2);
}

function isDate(value) {
    if (typeof value !== "string") {
        return false;
    }
    let isDate = parse(value).isValid;
    let isIso = DateTime.fromISO(value, { setZone: true }).isValid;
    return isDate || isIso;
}

export function getOptions() {
    return {
        zone: getTimeZone(),
        locale: getLocale()
    };
}

export function parse(text) {
    return DateTime.fromFormat(text, "D", getOptions());
}

export function format(dateTime) {
    return dateTime.toLocaleString(DateTime.DATE_SHORT);
}

export function localize(dateTime) {
    return dateTime
        .setZone(getTimeZone())
        .setLocale(getLocale())
}

export default {
    formatIsoAsDate,
    formatIsoAsDateTimeOffset,
    formatIsoAsTime,
    formatIsoAsTimeWithOffset,
    formatIsoAsTimespan,
    formatOffset,
    toIsoOffset,
    tryParseOffset,
    getCurrentDateIso,
    toDateIso,
    setTime,
    getDifference,
    addDays,
    isDate,
    getOptions,
    parse,
    format,
    localize
};
