import store from "@/store/store";

function getErrorMessage(e) {
    let message = e.response.data.message;
    if (e.response.status === "403") {
        return message + ". You do not have the required role to perform this action."
    }
    if (e.response.status === 404) {
        if (message !== null && typeof message !== "undefined") {
            return message;
        }
        return "Error 404: Not Found";
    }
    return message;
}

export function handleError(e) {
    store.dispatch("alert/error", getErrorMessage(e));
}

export default {
    getErrorMessage,
    handleError,
};
