import axios from "axios"
import securityService from "@/services/securityService";

function getDefaultState() {
    return {
        timeZone: "",
        cultureCode: null,
        isLoaded: false
    }
}

const state = getDefaultState;

const actions = {
    async load({ commit }) {
        let user = await securityService.getCurrentUser();

        const culture = (await axios.get("/api/Cultures/" + user.cultureId)).data;
        const timeZone = (await axios.get("/api/TimeZones/" + user.timeZoneId)).data;

        commit("set", { culture, timeZone });
    },
    clear({ commit }) {
        commit("clear");
    }
};

const mutations = {
    set(state, { culture, timeZone }) {
        state.cultureCode = culture.code;
        state.timeZone = timeZone.name;
        state.isLoaded = true;
    },
    clear(state) {
        Object.assign(state, getDefaultState());
    }
};

export const localisation = {
    namespaced: true,
    state,
    actions,
    mutations
};
