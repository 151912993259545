import Vue from 'vue';
import securityService from '../services/securityService'

export default Vue.mixin({
    methods: {
        getFilter(filter) {
            let labTesterId = securityService.getLabTesterId();
            let newFilter = filter ? filter : {};
            if (labTesterId) {
                newFilter = { ...filter, labTesterId };
            }
            return newFilter;
        }
    }
})
